<template>
	<div>
		<multiselect
			v-model="selected"
			:options="options"
			:class="validateError != '' ? 'box-border-color' : ''"
			label="text"
			track-by="value"
			:placeholder="$t('select')"
			:multiple="multiple"
			:close-on-select="!multiple"
			:clear-on-select="!multiple"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			@input="handleInput($event, multiple)"
		>
            <span slot="noOptions">
                {{ $t("no_options") }}
            </span>
			<span slot="noResult">
                {{ $t("no_result") }}
            </span>
			<template slot="selection" slot-scope="{ values, search, isOpen }" v-if="multiple">
				<span class="multiselect__single" v-if="values.length && !isOpen">
					{{ translateNSelected(values) }}
				</span>
			</template>
		</multiselect>
		<span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
	</div>
</template>

<script>
	// Helpers
	import translateNSelected from "@/helpers/translateNSelected";
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";

	// Services
	import ProgramService from "@/services/ProgramService";

	// Other
	import qs from "qs";

	export default {
		props: {
			value: {
				default: null,
			},
			validateError: {
				type: String,
				default: "",
			},
			multiple: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				selected: this.value,
				options: [],
			};
		},
		watch: {
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options, this.multiple)
			}
		},
		created() {
			this.clearOptions();
			this.getOptions()
		},
		methods: {
			translateNSelected: translateNSelected,
			handleInput: handleInput,
			setSelected: setSelected,
			getOptions() {
				const config = {
					params: {
						sort: ("name"),
						limit: -1,
					},
					paramsSerializer: (params) => qs.stringify(params, {encode: false})
				}

				this.clearOptions();
                ProgramService.getGroupsPublic(config)
                                 .then((response) => {
                                     const data = response.data.data;
                                     data.map((item) => {
                                         this.options.push({
                                             value: item.id,
                                             text: item.name,
                                         });
                                     });
                                 })
                                 .then(() => {
                                     if(this.value == "" || this.value == null) return
                                     this.selected = this.setSelected(this.value, this.options, this.multiple)
                                     if (this.selected == null || this.selected.length == 0) {
                                         this.$emit("input", null)
                                     }
                                 })

			},
			clearOptions() {
				this.options = [];
			}
		}
	}
</script>
